import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Eil: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Eil,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Eil"
          d="M1015.7,719c-1.1,1.2-1.6,3.2-0.9,4.7c-0.7,4.9,0.8,10.2,1.6,14.8c-0.6,4.7-2.4,14.3-3.1,18.9  c-0.5,1.7-2.2,6.4-2.8,8.1c0.2,2.4,0,5.7-0.3,8.1c-1.1,0.2-3-0.1-4.1-0.1l-0.6,2.2l-0.3,0.2l-0.5-0.1c-1,3.8-2.6,7.4-4,11   c9.2,1.1,11.5,2.8,13.7,11.9c4.3,11.7,2.5,17.6,2.4,29.1c2.1,7.3,5.6,16,3.9,23.7c-1.1,2.5-3.3,5.2-4.3,7.7 c-0.5,1.7-1.1,5.3-2.1,6.8c-3.8,3.4-2.4,4.7-2.4,9l0.2,0.2l0.1,0.6l-0.2,0.2l-46-44l-52,28l-24-6v-14h-38l2,12l-20,2 c-4.3-10.4-14.8-31.9-20-42l8-8l-20-22l30-30l29,4v-12l20-14l26-2l-12-14v-14l28-8l14,14h52l2,12h24l0.9,0.7L1015.7,719z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 912.2867 789.2833)"
        >
          Eil
        </text>
      </g>
    </>
  );
});
